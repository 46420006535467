import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ProjectCard from './ProjectCard';


const ProjectList = ({ projects, projectName }) => {

   //console.log(projects)
  return (
    <Row className="justify-content-left text-center" style={{marginBottom: '1rem'}}>
      {projects && (projects?.map((project, index) => (

          <ProjectCard key={index} project={project} projectName={projectName}/>

      )))}
    </Row>
  );
};

export default ProjectList;
