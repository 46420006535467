import React from 'react';
import { Container } from 'react-bootstrap';
import ProjectList from './ProjectList';
import { useResumeData } from '../../../Utils/context_api';
import styled from 'styled-components';

const ProjectsHeadline = styled.h1`
    color: #FFA500;
    font-family: Merriweather Sans, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    text-align: left;
    border-bottom: 1px solid white;
`

const ProjectMain = () => {
    const data = useResumeData()
    const projectsData = data.projectsData
    return (
        <Container id='recentProjects' >
            {/* <ProjectsHeadline>Recent Projects</ProjectsHeadline> */}
            <ProjectList projects={projectsData.recentProjects} />
        </Container>
    );
};

export default ProjectMain;
