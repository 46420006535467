import React from 'react'
import { ResumeDataProvider } from '../Utils/context_api';
import Main from '../Components_2.0/Main/main'
import NavBar from '../Components_2.0/Header/NavBar';

const MainRoutes = ({userName}) => {
    return (
        <>
            <ResumeDataProvider>
                <NavBar />
                {userName && <Main userName={userName} />}
            </ResumeDataProvider>
        </>
    )
}

export default MainRoutes