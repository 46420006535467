import React, { useState } from 'react';
import { Radio, Space, Tabs } from 'antd';
import ProjectList from './Projects/ProjectList';
import PreviousProjectMain from './Projects/PreviousProjectMain';
import { useResumeData } from '../../Utils/context_api';
import styled from 'styled-components';

const CustomTabNav = styled.div`

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    color: white;
    background-color: #212529;
    box-shadow: 0px 0px 15px #44464A;
    padding: .5rem 2.5rem;
    border-radius: 1rem;
    font-size: 1.8rem;
    &::after {
        transform: translate(-50%, -50%);
      }
  }

  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated{
    background-color: #3EDC84 !important;
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    
  }

  .ant-tabs .ant-tabs-ink-bar{
    background-color: #3EDC84 !important;
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    
  } 

  .ant-tabs .ant-tabs-tab {
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: white;

    &:hover{
        color: red;
    }
    &::after {
        transform: translate(-50%, -50%);
      }
  }
`

const CardText = styled.p`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    color: white;
    font-size: 2.5rem;
    text-align: center;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    
`;

const ProjectsHeadline = styled.h1`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    color: white;
    font-size: 3.5rem;
    text-align: left;
    margin-bottom: 4rem;
    margin-left: 2rem;
    border-bottom: 1px solid white;

`

const ExampleSection = () => {

    const data = useResumeData()
    const projectsData = data.projectsData.recentProjects

    return (
        <CustomTabNav>
            <ProjectsHeadline >Projects </ProjectsHeadline>
            <Tabs defaultActiveKey="0" tabPosition="left" style={{ height: 'auto', overflow:'hidden'}}>
                {projectsData && (projectsData?.map((project, index) => (
                    <Tabs.TabPane tab={project.name} key={index} style={{ fontSize: '2rem', height: '600px', width: '100%', overflowY: 'auto', overflowX: 'hidden'}}>
                        {/* prints project description */}
                        <CardText>{project.description}</CardText>
                        <ProjectList projects={projectsData} projectName={project.name} />
                    </Tabs.TabPane>
                )))}
                <Tabs.TabPane tab="Notable Projects" key={(projectsData?.length - 1) + 1} style={{ fontSize: '2rem ', height: '600px', width: '100%', overflowY: 'auto', overflowX: 'hidden'}}>
                    <PreviousProjectMain />
                </Tabs.TabPane>
            </Tabs>
        </CustomTabNav>

    );
};

export default ExampleSection;