import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useResumeData } from '../../../Utils/context_api'
import styled from 'styled-components';
import FedExLogo from '../../../images/FedEx_Freight.svg.png'

const ContributionsHeadline = styled.h1`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    color: white;
    font-size: 3.5rem;
    text-align: left;
    margin-bottom: 4rem;
    margin-left: 2rem;
    border-bottom: 1px solid white;

`
const ContributionTitle = styled.h2`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    text-shadow: 1px 1px 2px black;
    color: white;
    text-transform: uppercase;
    text-align: left;
    color: #3EDC84;
`;

const ContributionText = styled.p`
  color: white;
  font-size: 1.3rem;
  text-align: left;
  font-size: 1rem;
  padding-right: 10vw;
  padding-top: 0.5rem;
`;

const LangToolsText = styled.p`
color: white;
font-size: 1.3rem;
text-align: left;
font-size: 1rem;
padding-right: 10vw;
padding-top: 0.5rem;
`;

const ContributionMain = () => {
  const data = useResumeData();
  const resume = data.resumeData;
  return (
    <Container>
      <ContributionsHeadline>Professional Contributions</ContributionsHeadline>
      <div style={{textAlign: 'center'}}>
        <img src={FedExLogo} style={{width: 'auto', height: '20rem', padding: '5rem' }} />
      </div>
      <Row>
        <Col style={{ padding: '0 5rem' }}>
          {resume.experience && (
            <>
              <ContributionTitle>{resume.experience[0].title} {' - ' + resume.experience[0].company}</ContributionTitle>
              {Array.isArray(resume.experience[0].contributions) && (
                <>
                  {resume.experience[0].contributions.map((description, index) => (
                    <ContributionText key={index}>{description}</ContributionText>
                  ))}
                </>
              )}
            </>
          )}
        </Col>
        <Col>
          {resume.experience && (
            <>
              <ContributionTitle>Frameworks/Languages</ContributionTitle>
              <LangToolsText style={{ textAlign: 'left' }}>{resume.experience[0].languages}</LangToolsText>

              <ContributionTitle>Tools</ContributionTitle>
              <LangToolsText style={{ textAlign: 'left' }}>{resume.experience[0].tools}</LangToolsText>

            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default ContributionMain