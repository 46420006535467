import React from 'react';
import styled from 'styled-components';
import { FaLinkedin } from 'react-icons/fa'
import { VscGithubInverted } from 'react-icons/vsc'
import { Row, Col } from 'react-bootstrap'
const NavContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left:20rem;
    margin-right: 20rem;
    background-color: #212529;
    border-bottom-left-radius: 1rem; 
    border-bottom-right-radius: 1rem; 
    box-shadow: 0px 0px 11px #44464A;
    opacity: .90;
    height: 4rem;
    z-index: 2;

    @media screen and (max-width: 820px){
        margin-left:1rem;
        margin-right: 1rem;
    }
`
const SocialsContainer = styled.div`
    display: flex;
    justify-content: end;
    margin: .5rem 0;
    background-color: transparent;
`
const SocialsLink = styled.a`
    margin-right: 3rem;
    background-color: transparent;
    color: white;

    &:hover{
        transition: 0.5s all ease;
        opacity: 0.5;
        cursor: pointer;
     }
`
const NavLinksContainer = styled.div`
    display: flex;
    justify-content: start;
    margin: .5rem 0;
    background-color: transparent;
    width: 100%;
`

const NavLink = styled.a`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    margin-left: 1rem;
    background-color: transparent;
    text-decoration: none;
    font-size: 1.3vw;
    color: white;

    &:hover{
        transition: 0.5s all ease;
        opacity: 0.8;
        color: red !important;
        cursor: pointer;
     }

     @media screen and (max-width: 820px){
        display: none;
    }
`

const NavBar = () => {
    return (
        <NavContainer>
            <Row style={{backgroundColor: 'transparent'}}>
                <Col style={{backgroundColor: 'transparent'}}>
                    <NavLinksContainer>
                        <NavLink href="#home">Home</NavLink>
                        <NavLink href="#projects">Projects</NavLink>
                        {/* <NavLink href="#contributions">Contributions</NavLink> */}
                    </NavLinksContainer>
                </Col>
                <Col style={{backgroundColor: 'transparent'}}>
                    <SocialsContainer>
                        <SocialsLink href='https://github.com/jpaniag2' target="_blank"><VscGithubInverted style={{ backgroundColor: 'transparent' }} size='2.5rem' /></SocialsLink>
                        <SocialsLink href="https://www.linkedin.com/in/juliopaniaguaalanis/" target="_blank"><FaLinkedin style={{ backgroundColor: 'transparent' }} size='2.5rem' /></SocialsLink>
                    </SocialsContainer>
                </Col>
            </Row>
        </NavContainer>
    )
}

export default NavBar