import React from 'react'
import { useResumeData } from '../../Utils/context_api';
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

const UserSection = styled.h1`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    font-size: 1.3vw;
    text-shadow: 1px 1px 2px black;
    color: #3EDC84;
    background-color: transparent;
    text-transform: uppercase;
    justify-content: center;
    text-align: left;
    position: absolute;
    left: 21.5rem;
    top: 6.5rem;

    @media screen and (max-width: 820px){
        font-size: 3rem;
    }
`
const UserSectionMe = styled.h1`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    font-size: 6vw;
    text-shadow: 1px 1px 2px black;
    color: white;
    background-color: transparent;
    text-transform: uppercase;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 820px){
        font-size: 3rem;
    }
`

const UserSectionIcon = styled.p`
    font-size: .8rem;
    color: white;
    background-color: transparent;
    justify-content: center;
    text-align: center;
    padding-top: 2rem;

    @media screen and (max-width: 820px){
        font-size: 1.3rem;
    }
`

const UserSubSection = styled.h2`
    font-family: Merriweather Sans, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.3rem;
    letter-spacing: .05rem;
    text-shadow: 1px 1px 2px #3EDC84;
    color: white;
    background-color: transparent;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 820px){
        font-size: 1.3rem;
    }
`

const UserCustomDiv = styled.div`
padding-top: 10rem;
text-align: center;

 @media screen and (max-width: 820px){
    position: static;
    top: 0;
    left: 0;
}

`

const User = ({ userName }) => {

    const data = useResumeData();
    const resume = data.resumeData;

    return (
        <Container fluid>
            <UserSection>
                Welcome, {userName}!
            </UserSection>
            <UserCustomDiv>
                <UserSectionMe >
                    I'm {resume.name}.
                </UserSectionMe>
                <UserSubSection>
                Software Engineer & Entrepreneur | Specializing in Full-Stack & Front-End development.
                </UserSubSection>
                <UserSectionIcon>*Icons are interactive, go ahead drag one.</UserSectionIcon>
            </UserCustomDiv>
        </Container>

    )
}

export default User