import React from 'react';
import { Container } from 'react-bootstrap';
import PreviousProjectList from './PreviousProjectList';
import { useResumeData } from '../../../Utils/context_api';
import styled from 'styled-components';

const PreviousProjectsHeadline = styled.h1`
    color: #FFA500;
    font-family: Merriweather Sans, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    text-align: left;
    border-bottom: 1px solid white;
`

const PreviousProjectMain = () => {
    const data = useResumeData()
    const projectsData = data.projectsData
    return (
        <Container fluid id='previousProjects'>
            {/* <PreviousProjectsHeadline>Previous Projects</PreviousProjectsHeadline> */}
            <PreviousProjectList projects={projectsData.previousProjects} />
        </Container>
    );
};

export default PreviousProjectMain;