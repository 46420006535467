import React from 'react'
import { Container } from 'react-bootstrap';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="text-light text-center py-3" >
        <Container style={{padding: '10rem 0'}}>
          <span>© {currentYear} Crunchy Dev | All rights reserved</span>
        </Container>
      </footer>
    
    )
}

export default Footer