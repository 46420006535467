import React, { useState, useEffect } from 'react';
import { Form, Input, Radio, Button } from 'antd';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const QuestionnaireHeadline = styled.h1`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    color: white;
    font-size: 5.5rem;
    text-align: center;
    margin-left: 2rem;
    border-bottom: 1px solid white;
`;

const SubHeadline = styled.p`
    color: #3EDC84;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    margin-top: 4.9rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0rem;
`;

const StyledFormItem = styled(Form.Item)`
  label {
    color: white !important; // Important to override Ant Design's default styling
  }
`;

const StyledButton = styled.button`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    background-color: #3EDC84;
    color: black;
    border: none;
    padding: 5px 30px;
    cursor: pointer;
    border-radius: .5rem;
    transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: white;
    color: black;
  }
`;

const QuestionnaireAntDesign = ({ onQuestionnaireComplete }) => {
    const [name, setName] = useState('');
    const [personType, setPersonType] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        return () => {
            // Cleanup function to cancel any ongoing tasks or subscriptions
            setLoading(false); // Reset loading state when the component is unmounted
        };
    }, []); 

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePersonTypeChange = (event) => {
        setPersonType(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true); // Set loading to true when the form is submitted
        setTimeout(() => {
            if (name && personType) {
                onQuestionnaireComplete(name);
            } else {
                alert('Please fill out all fields');
            }
            setLoading(false); // Set loading to false after 2 seconds
        }, 2000); // 2 seconds delay
    };

    return (
        <Container fluid style={{ backgroundColor: '#212529' }}>          
            <div style={{ padding: '15rem 10rem'}}>
                <QuestionnaireHeadline>Hi, Welcome to my tech portfolio! 🙂</QuestionnaireHeadline>
                <Form onFinish={handleSubmit} style={{padding: '0 25%'}}>
                <SubHeadline>Kindly fill out this before continuing.</SubHeadline>
                    <StyledFormItem label="Name" name="name" rules={[{ required: true, message: 'Please input your name' }]}>
                        <Input value={name} onChange={handleNameChange} />
                    </StyledFormItem>
                    <StyledFormItem label="You're a:" name="personType" rules={[{ required: true, message: 'Please select an option' }]}>
                        <Radio.Group value={personType} onChange={handlePersonTypeChange}>
                            <Radio value="recruiter">Recruiter</Radio>
                            <Radio value="manager">Manager</Radio>
                            <Radio value="other">Other</Radio>
                        </Radio.Group>
                    </StyledFormItem>
                    <Form.Item style={{textAlign: 'right'}}>
                        <StyledButton type="primary" htmlType="submit" loading={loading.toString()}>
                            Submit 
                            {loading && <LoadingOutlined style={{zIndex: '1', padding: '0 .5rem'}}/>}
                        </StyledButton>
                        
                    </Form.Item>
                </Form>
            </div>
        </Container>

    );
};

export default QuestionnaireAntDesign;




