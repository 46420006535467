import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const ScrollableImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-radius: 1rem;
  width: 100%;
  height: 500px; /* Set a fixed height or adjust as needed */
`;

const ScrollableImageContent = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 1rem;
  transition: transform 10s ease; /* Smooth transition effect */
  transform: translateY(${(props) => props.scrollY}px);
`;

const ScrollableImageComponent = ({ image }) => {
  const containerRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);

  const handleMouseEnter = () => {
    setScrollY(-(containerRef.current.scrollHeight - containerRef.current.clientHeight));
  };

  const handleMouseLeave = () => {
    setScrollY(0);
  };

  return (
    <ScrollableImageContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={containerRef}
    >
      <ScrollableImageContent scrollY={scrollY} src={image.src} alt="Scrollable Image" />
    </ScrollableImageContainer>
  );
};

export default ScrollableImageComponent;



