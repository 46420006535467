import React from 'react'
import { Container } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion'
import AndroidIcon from '../../images/android_icon.PNG'
import SwiftIcon from '../../images/swift_icon.png'
import ReactIcon from '../../images/react_icon.png'
import AngularIcon from '../../images/angular_icon.svg.png'

import { Image, TopLine, Introduction } from './ExperienceIconsStyle';

const ExperienceIcons = () => {
    const animationControlIcon1 = useAnimation();
    const animationControlIcon2 = useAnimation();
    const animationControlIcon3 = useAnimation();
    const animationControlIcon4 = useAnimation();

    const x = useMotionValue(0)
    const scale = useTransform(x, [-200, 900], [1.5, 0.5])
    const rotate = useTransform(x, [0, 300], [-60, 60])
    const scale1 = useTransform(x, [-900, 200], [1.5, 0.5])
    const rotate1 = useTransform(x, [-150, 150], [-90, 120])

    const { inView, ref } = useInView();

    if (inView) {
        animationControlIcon1.start({
            rotate: 360, opacity: 1, x: 1100, y: 150, transition: {
                type: "spring", damping: 10,
                mass: 0.75, stiffness: 100, duration: 1, delay: 1
            }
        });

        animationControlIcon2.start({
            opacity: 1, x: 150, y: 100, transition: { duration: 2, delay: 2 }
        })

        animationControlIcon3.start({
            rotate: 360, transition: { duration: 4, ease: "linear", repeat: Infinity }
        })

        animationControlIcon4.start({
            opacity: 1, x: 50, y: 200, transition: { duration: 3, delay: 2 }
        })
    }
    return (

        <Container fluid ref={ref} style={{ paddingTop: '0%', paddingBottom: '20%' }}>
            {/* <br></br>
            <Introduction>Frameworks I've Worked With</Introduction>
            <br></br> */}
            {/* <TopLine>*Icons are interactive, go ahead drag one.</TopLine> */}
            <Container ref={ref}>
                <Image
                    src={AndroidIcon}
                    alt='androidIcon'
                    whileTap={{ scale: 0.8 }}
                    drag={true}
                    dragConstraints={{ left: 1000, right: 1100, bottom: 150, top: 150 }}
                    initial={{ opacity: 0, x: 200, y: 400 }}
                    animate={animationControlIcon1}
                    whileHover={{ cursor: "grabbing" }}
                    style={{ position: 'absolute' }}
                />

                <Image
                    src={AngularIcon}
                    alt='angularIcon'
                    whileHover={{ cursor: "grabbing" }}
                    //whileTap={{ scale: 0.9 }}
                    //drag={true}
                    //dragConstraints={{ left: 0, right: 300, bottom: 0, top: 0 }}
                    initial={{ opacity: 0, x: 150, y: 100 }}
                    //animate={{opacity: 1, x: 0, y: 0, transition: { duration: 2, delay: 2 }}}
                    animate={animationControlIcon2}
                    style={{
                        x: x,
                        scale: scale,
                        rotate: rotate,
                        cursor: "grab",
                    }}
                    drag={true}
                    dragConstraints={{ left: 0, right: 150, bottom: 100, top: -100 }}
                    dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                    whileTap={{ cursor: "grabbing" }}
                // style={{position: 'fixed', zIndex: '-1'}}
                />
                <Image
                    src={ReactIcon}
                    alt='reactIcon'
                    whileTap={{ scale: 0.8 }}
                    drag={true}
                    dragConstraints={{ left: 600, right: 600, bottom: 100, top: -10 }}
                    initial={{ opacity: 1, x: 550, y: 100 }}
                    animate={animationControlIcon3}
                    whileHover={{ cursor: "grabbing" }}
                />
                <Image
                    // src={AngularIcon}
                    // alt='angularIcon'
                    // whileTap={{ scale: 0.8 }}
                    // drag={true}
                    // dragConstraints={{ left: 300, right: 900, bottom: 300, top: 300 }}
                    // dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                    // initial={{ opacity: 1, x: 400, y: 500 }}
                    // animate={animationControlIcon4}
                    // whileHover={{ cursor: "grabbing" }}
                    src={SwiftIcon}
                    alt='swiftIcon'
                    whileTap={{ scale: 0.8 }}
                    drag={true}
                    dragConstraints={{ left: -200, right: 200, bottom: 150, top: 150 }}
                    dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                    initial={{ opacity: 1, x: -1500, y: 200 }}
                    animate={animationControlIcon4}
                    whileHover={{ cursor: "grabbing" }}
                />
            </Container>

        </Container>
    )
}

export default ExperienceIcons