import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import User from './User'
import Education from './Education'
import ProjectMain from './Projects/ProjectMain'
import PreviousProjectMain from './Projects/PreviousProjectMain'
import introBG from '../../images/intro_bg.jpg'
import ExampleSection from './ExampleSection'
import ExperienceIcons from '../../Components/ExperienceIcons/index'
import styled from 'styled-components'
import Footer from '../Footer'
import ContributionMain from './Contributions/ContributionMain'


const ProjectsInfo = styled.p`
  color: #1677FF;
  font-size: 1rem;
  text-align: left;
  padding-bottom: 4rem;
`;

const Main = ({ userName }) => {

    return (
        <div id='home' style={{ backgroundColor: '#212529', overflow: 'hidden' }}>
            {userName && <User userName={userName} />}
            <ExperienceIcons />


            {/* <div style={{ overflow: 'hidden', backgroundColor: "transparent" }}>
                <Row style={{ height: '100vh', backgroundImage: `url(${introBG})`, backgroundSize: 'cover', backgroundAttachment: 'unset' }}>
                    
                    <Col style={{ zIndex: '1', backgroundColor: "transparent"}} >
                        <User />
                    </Col>
                </Row>
            </div> */}

            <Container fluid='xxl'>
                <Row id='projects' style={{ padding: '5rem 0' }}>
                    {/* <ProjectsInfo>Select tab to view more.</ProjectsInfo> */}
                    <ExampleSection />
                </Row>
            </Container>

            {/* <Container fluid='xxl'>
                <Row id='contributions' style={{ padding: '5rem 0' }}>
                    <ContributionMain />
                </Row>
            </Container> */}

            <div>
                <Footer />
            </div>
        </div>
    )
}

export default Main