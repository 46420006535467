import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PreviousProjectCard from './PreviousProjectCard';


const PreviousProjectList = ({ projects }) => {

   //console.log(projects)
  return (
    <Row className="justify-content-left text-center" style={{marginBottom: '1rem'}}>
      {projects && (projects?.map((project, index) => (
        <Col key={index} md={6} style={{marginBottom: "1rem"}}>
          <PreviousProjectCard project={project} />
        </Col>
      )))}
    </Row>
  );
};

export default PreviousProjectList;