import React, { useState } from 'react';
import { Card, Row, Col, Tab, Tabs, Modal, Container } from 'react-bootstrap';
import { CheckCircleOutlined, CoffeeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ScrollableImageComponent from '../../Utils/ScollableImageComponent';
import AWS_ScreenShot from '../../../images/acapulquena.com_.png';
import ATVD_ScreenShot from '../../../images/acapulquena_tv_display.png'
import AFB_ScreenShot from '../../../images/acapulquena_firebase_func.png'
import CDFE_ScreenShot from '../../../images/crunchy_ads_frontend.png'
import CDFE_ScreenShot2 from '../../../images/crunchy_ads_cover.png'
import CDBE_ScreenShot from '../../../images/crunchy_ads_backend.png'
import ABWS_ScreenShot from '../../../images/theaztecabakery.png'
import ABWS_ScreenShot2 from '../../../images/azteca_webapp.png'

import WagApp_Pic from '../../../images/WagApp.png'
import GymMem_Pic from '../../../images/GymMembership.png'
import CampEats_Pic from '../../../images/CampusEatsDatabase.png'
import Cindys_Pic from '../../../images/CindysCatering.png'

const CardText = styled.p`
  color: #3EDC84;

  font-size: 1rem;
  text-align: center;
`;

const CardTitle = styled.h2`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    text-shadow: 1px 1px 2px black;
    color: white;
    text-transform: uppercase;
    text-align: center;
    color: white;
`;

const TabTitle = styled.h2`
  font-family: Merriweather Sans, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: .9rem;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 2px gray;
  background-color: transparent;
`;

const ImageContent = styled.img`
  position: relative;
  width: 100%;
  height: 300px;
  object-position: top;
  object-fit: cover;
  border-radius: 1rem;
  transition: opacity 0.3s ease; /* Smooth transition effect for opacity */
  cursor: pointer;

  &:hover {
    opacity: 0.5;

    &::after {
      content: 'See more';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: white;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 10px;
      border-radius: 5px;
    }
  }

  &::after {
    content: 'See More'; /* To make ::after work, even without content */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

const CustomTabs = styled(Tabs)`
  .nav-link.active {
    background-color: #212529; // Change this color to your desired active background color
    color: white; // Change this color to your desired active text color
    
    
  }
  .nav-link{
    background-color: #e0e0e0;
    color: black;
    border-color: black;
    margin-bottom: .4px;

    &:hover{
        opacity: 0.8;
        transition: background-color 0.5s ease, color 0.3s ease, border-color 0.3s ease;
      }
  }

`;

const CustomCheckCircle = styled(CheckCircleOutlined)`
    color: #3EDC84;
    position: relative;
    left: 48%;
`

const CustomCoffeeOutlined = styled(CoffeeOutlined)`
    color: #ffbd03;
`


const PreviousProjectCard = ({ project }) => {
    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const imageHashMap = {
        'Wag Android App': WagApp_Pic,
        'Gym Membership System' : GymMem_Pic,
        'Campus Eats Database' : CampEats_Pic,
        'Cindys Catering' : Cindys_Pic
    };

    const TabImgHashMap = {
        'Wag Android App': WagApp_Pic,
        'Gym Membership System' : GymMem_Pic,
        'Campus Eats Database' : CampEats_Pic,
        'Cindys Catering' : Cindys_Pic
    };

    const projectData = Object.values(project);
    const data = projectData[4];

    const modalContent = (
        <Modal show={showModal} onHide={handleModal} size="xl" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: '#212529' }}>
                <Modal.Title >
                    <div style={{padding: '0 3.5rem', textAlign: 'center'}}>
                        <CardTitle >{project.type}</CardTitle>
                        {/* <CardText>{project.description}</CardText> */}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#212529' }}>
                <Container>
                    <Row>
                        <Col md={8}>
                            <CustomTabs>
                                {Array.isArray(data) &&
                                    data.map((subProject, index) => (
                                        <Tab
                                            key={index}
                                            eventKey={`tab${index + 1}`}
                                            title={subProject.projectName}
                                            style={{
                                                backgroundColor: 'transparent',
                                                borderBottomLeftRadius: '1rem',
                                                borderBottomRightRadius: '1rem',
                                            }}
                                        >
                                            {TabImgHashMap[subProject.projectName] && (
                                                <ScrollableImageComponent
                                                    image={{ src: TabImgHashMap[subProject.projectName], height: 600 }}
                                                />
                                            )}
                                        </Tab>
                                    ))}
                            </CustomTabs>
                        </Col>
                        <Col md={4} className="d-flex flex-column align-items-center justify-content-center" style={{borderLeft: '1px solid white'}}>
                            
                            {Array.isArray(data) &&
                                data.map((subProject, index) => (
                                    <div key={index}>
                                        {subProject.status === true ? (<> <CustomCheckCircle  /><p style={{fontSize: '1rem', color: 'white', textAlign: 'center'}}>Completed</p></>) : (<><CustomCoffeeOutlined/><p style={{fontSize: '1rem', color: 'white', alignText: 'center'}}>Dev In Progress</p></>)}
                                        <CardTitle style={{ fontSize: '2rem' }}>{subProject.projectName}</CardTitle>
                                        <CardText key={index} style={{ textAlign: 'center' }}>{subProject.languages}</CardText>
                                        <CardText style={{ color: '#e0e0e0', fontSize: '1rem', textAlign: 'center', marginLeft: '1rem' }}>{subProject.description}</CardText>
                                    </div>

                                ))}

                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );

    return (
        <div style={{ height: '100%' }}>
            <Row style={{ padding: '0rem 0.5rem' }}>
                <Col>
                    <CardTitle>{project.name}</CardTitle>
                    <CardText>{project.languages}</CardText>
                    <div onClick={handleModal}>
                        {Array.isArray(data) &&
                            data.map((subProject, index) => (
                                imageHashMap[subProject.projectName] && (<ImageContent key={index} src={imageHashMap[subProject.projectName]} alt="Project Image" />)
                            ))}
                    </div>
                    {modalContent}
                </Col>
            </Row>
        </div>
    );
};

export default PreviousProjectCard;