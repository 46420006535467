import ResumeData from '../Data/resume_data.json'
import ProjectsData from '../Data/projects_data.json'

const fetchResume = async () => {
  try {
     return ResumeData;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};

const fetchProjects = async () => {
    try {
      // Assuming ProjectsData is a JSON object with project data
      return ProjectsData;
    } catch (error) {
      console.error('Error fetching projects:', error.message);
      throw error;
    }
  };

export {fetchResume, fetchProjects};