import React, { useState } from 'react';
import { Card, Row, Col, Tab, Tabs, Modal, Container } from 'react-bootstrap';
import { CheckCircleOutlined, CoffeeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ScrollableImageComponent from '../../Utils/ScollableImageComponent';
import AWS_ScreenShot from '../../../images/acapulquena.com_.png';
import ATVD_ScreenShot from '../../../images/acapulquena_tv_display.png'
import AFB_ScreenShot from '../../../images/acapulquena_firebase_func.png'
import CDFE_ScreenShot from '../../../images/crunchy_ads_frontend.png'
import CDFE_ScreenShot2 from '../../../images/crunchy_ads_cover.png'
import CDBE_ScreenShot from '../../../images/crunchy_ads_backend.png'
import ABWS_ScreenShot from '../../../images/theaztecabakery.png'
import ABWS_ScreenShot2 from '../../../images/azteca_webapp.png'

const CardText = styled.p`
  color: #3EDC84;

  font-size: 1.3rem;
  text-align: center;
`;

const CardTitle = styled.h2`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    text-shadow: 1px 1px 2px black;
    color: white;
    text-transform: uppercase;
    text-align: center;
    color: white;
`;

const TabTitle = styled.h2`
  font-family: Merriweather Sans, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: .9rem;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 2px gray;
  background-color: transparent;
`;

const ImageContent = styled.img`
  position: relative;
  width: 100%;
  height: 300px;
  object-position: top;
  object-fit: cover;
  border-radius: 1rem;
  transition: opacity 0.3s ease; /* Smooth transition effect for opacity */
  cursor: pointer;

  &:hover {
    opacity: 0.5;

    &::after {
      content: 'See more';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: white;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 10px;
      border-radius: 5px;
    }
  }

  &::after {
    content: 'See More'; /* To make ::after work, even without content */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

const CustomTabs = styled(Tabs)`
  .nav-link.active {
    background-color: #212529; // Change this color to your desired active background color
    color: white; // Change this color to your desired active text color
    
    
  }
  .nav-link{
    background-color: #e0e0e0;
    color: black;
    border-color: black;
    margin-bottom: .4px;

    &:hover{
        opacity: 0.8;
        transition: background-color 0.5s ease, color 0.3s ease, border-color 0.3s ease;
      }
  }

`;

const StyledButton = styled.button`
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    background-color: #3EDC84;
    color: black;
    border: none;
    padding: 5px 30px;
    cursor: pointer;
    border-radius: .5rem;
    transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: white;
    color: black;
  }
`;

const CustomCheckCircle = styled(CheckCircleOutlined)`
    color: #3EDC84;
`

const CustomCoffeeOutlined = styled(CoffeeOutlined)`
    color: #ffbd03;
`

const { TabPane } = Tabs;

const ProjectCard = ({ project, projectName }) => {

    const [showModal, setShowModal] = useState(false);
    const [selectedProjectName, setSelectedProjectName] = useState("");

    const handleModal = (projectName) => {
        setShowModal(!showModal);
        setSelectedProjectName(projectName)
    };

    const imageHashMap = {
        'Online Ordering Website': AWS_ScreenShot,
        'Ads/Market Management Dashboard': CDFE_ScreenShot2,
        'Azteca Bakery Website': ABWS_ScreenShot2
    };

    const TabImgHashMap = {
        'Online Ordering': AWS_ScreenShot,
        'Digital Menu Display': ATVD_ScreenShot,
        'Serverless Proxy': AFB_ScreenShot,
        'Ads/Market Management Dashboard': CDFE_ScreenShot,
        'Ads/Market API': CDBE_ScreenShot,
        'Azteca Bakery Website': ABWS_ScreenShot

    };

    const projectData = Object.values(project);
    const data = projectData[4];
    const pName = data.name;

    const modalContent = () => (
        <Modal show={showModal} onHide={handleModal} size="xl" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: '#212529' }}>
                <Modal.Title>
                    <div style={{ padding: '0 3.5rem', textAlign: 'center' }}>
                        <CardTitle>{selectedProjectName}</CardTitle>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#212529' }}>
                <div onClick={handleModal}>
                    {TabImgHashMap[selectedProjectName] && (
                        <ScrollableImageComponent
                            image={{ src: TabImgHashMap[selectedProjectName], height: 600 }}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );

    const tabContent = (
        <Container>
            {Array.isArray(data) &&
                data.map((subProject, index) => (
                    projectName === subProject.id ? (
                        <Row key={index} style={{ padding: '5rem 0', borderBottom: '1px solid white' }}>
                            <Col md={6}>
                                <div onClick={() => handleModal(subProject.projectName)}>
                                    {TabImgHashMap[subProject.projectName] && (
                                        <ImageContent src={TabImgHashMap[subProject.projectName]} alt="Project Image" />
                                    )}
                                </div>
                                {modalContent()}
                            </Col>
                            <Col key={subProject.id}>
                                {subProject.status === true ? (<> <CustomCheckCircle  /><p style={{fontSize: '0.8rem', color: 'white'}}>Production & Maintenance Stages</p></>) : (<><CustomCoffeeOutlined/><p style={{fontSize: '0.8rem', color: 'white'}}> Development & Prototype Stages</p></>)}
                                <CardTitle style={{ fontSize: '1.8rem' }}>{subProject.projectName}</CardTitle>
                                <CardText key={index} style={{ textAlign: 'center' }}>{subProject.languages}</CardText>
                                <CardText style={{ color: 'white', fontSize: '1rem', textAlign: 'center', marginLeft: '1rem' }}>{subProject.description}</CardText>
                                {subProject.website && (
                                    <a href={subProject.website} target="_blank" rel="noopener noreferrer">
                                        <StyledButton type="button">View Website</StyledButton>
                                    </a>
                                )}
                                {subProject.codeSnippet && (
                                    
                                    <a href={subProject.codeSnippet} target="_blank" rel="noopener noreferrer">
                                        <StyledButton type="button">View Code Snippet</StyledButton>
                                    </a>
                                )}
                            </Col>
                        </Row>
                    ) : ("")
                ))}
        </Container>
    );

    return (
        <div style={{ height: '100%' }}>
            {tabContent}
        </div>
    );
};

export default ProjectCard;

