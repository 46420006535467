import { createContext, useContext, useState, useEffect } from 'react';
import {fetchResume, fetchProjects} from '../Utils/resume_api';

const ResumeDataContext = createContext();

export const useResumeData = () => {
  return useContext(ResumeDataContext);
};

export const ResumeDataProvider = ({ children }) => {
  const [resumeData, setResumeData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);

  const fetchData = async () => {
    try {
      const resume = await fetchResume();
      const projects = await fetchProjects();

      setResumeData(resume);
      setProjectsData(projects);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const contextValue = {
    resumeData,
    projectsData,
  };

  return (
    <ResumeDataContext.Provider value={contextValue}>
      {children}
    </ResumeDataContext.Provider>
  );
};