import React from 'react'
import { useResumeData } from '../../Utils/context_api';
import styled from 'styled-components'

const EducationSection = styled.div`
    font-family: Righteous, sans-serif;
    font-weight: 400;
    font-size: 2rem;
    color: white;
`

const EducationItem = styled.p`
    font-family: Merriweather Sans, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4rem;
    color: white;
`

const Education = () => {

    const data = useResumeData();
    const resume = data.resumeData;
    return (
<div id='experience' >
  <EducationSection>Education</EducationSection>
  <p />
  {resume.education && (
    <>
      <EducationItem>{resume.education.university}</EducationItem>
      <EducationItem>{resume.education.degree} {resume.education.year}</EducationItem>
      <EducationItem>{"Honors: "} {resume.education.honors}</EducationItem>
      {Array.isArray(resume.education.organizations) && (
        <div>
            <EducationSection>Organizations</EducationSection>
          {resume.education.organizations.map((org, index) => (
            <EducationItem key={index}>{org}</EducationItem>
          ))}
        </div>
      )}
    </>
  )}
</div>
    )
}

export default Education