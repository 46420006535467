import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
import HomeContents from './Components/HomeContents';
import MainRoutes from './Components_2.0/MainRoutes';
import Questionnaire from './Components_2.0/Questionnaire';

function App() {

  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);
  const [name, setName] = useState('');

  const handleQuestionnaireComplete = (submittedName) => {
    setQuestionnaireCompleted(true);
    setName(submittedName);
  };

  return (
    <Router>

    <ScrollToTop />

      <Routes >
        <Route path="/" element={questionnaireCompleted ? <MainRoutes userName={name} /> : <Questionnaire onQuestionnaireComplete={handleQuestionnaireComplete} />} />
      </Routes>

    </Router>  
    // <ResumeDataProvider>
    //   <NavBar />
    //   <Main />
    // </ResumeDataProvider>

  );
}

export default App;
